<template>
    <div>
        <section class="ftco-section">
            <div class="container" >
                <div class="row justify-content-center pb-5">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-2">{{topic}}</h2>
                        <h5 class="text-secondary"><u> Information </u></h5>
                        <h5 class="text-dark pt-3">{{SiteContent.name}}</h5>                        
                        <p class="text-dark"><span class="icon fa fa-map marker"></span> {{SiteContent.addr}}</p>
                        <p class="text-dark"><span class="icon fa fa-phone"></span> {{SiteContent.phone}}</p>
                        <p class="text-dark"><span class="icon fa fa-paper-plane"></span> {{SiteContent.email}}</p>

                        <div class="pt-3">
                            <h5 class="text-secondary"><u> Social Media </u></h5>
                            <a :href="SiteContent.facebookurl" target="_blank"><i class="fa fa-facebook-square" style="font-size:36px"></i> <span class="text-dark">{{SiteContent.facebookurl.replace('https://www.facebook.com/','')}}</span></a><br><br>
                            <p><img class="float-rigth" style="width:30%; height:auto" :src="require('../assets/images/QR_Facebook.jpg')"></p>
                            <a :href="SiteContent.youtubeurl" target="_blank"><i class="fa fa-youtube-square" style="font-size:36px; color:#FF0000"></i> <span class="text-dark">Church Music Association CMA</span></a>
                            <p><img class="float-rigth" style="width:30%; height:auto" :src="require('../assets/images/QR_Youtube.jpg')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    </div>
</template>

<script>
import EventBus from "../Event-Bus.vue";
import SiteDataService from "../services/SiteDataService.vue";

export default {
    data(){
        return{
            topic:"Contact",
            SiteContent:{}
        }
    },
    methods:{
        GetSiteContent(lang){
            SiteDataService.SiteContentGet(lang)
                .then(res => {
                    this.SiteContent = res.data;
                })
                .catch(err => {
                    console.log(err);
                });
        }        
    },
    mounted(){
        let tVue = this;
        EventBus.$on("ContactLabel", (_topic) => {
            tVue.topic = _topic;
        });

        let cklang = this.$cookies.get('showlang');
        cklang = (cklang == null ? 'en' : 'th');
        this.GetSiteContent(cklang);
    }
}
</script>

<style scoped>
</style>